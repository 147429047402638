













import { Component, Vue, Watch } from 'vue-property-decorator'
import Headers from '@/layout/headers.vue'
import Footer from '@/layout/footer.vue'

@Component({
  components: {
    Headers,
    Footer
  }
})
export default class Layout extends Vue {
  @Watch('$route', { immediate: true, deep: true })
  routeChange(route: any) {
    // console.log(sessionStorage.getItem('applyFlag'), 'dddd>>>>')
    // let applyArr: any = []
    // let actArr: any = []
    // if (sessionStorage.getItem('applyFlag') === '1') {
    //   applyArr = ['MyCooperatioIndex']
    // } else {
    //   applyArr = []
    // }
    // if (sessionStorage.getItem('actFlag') === '1') {
    //   actArr = ['CampaignsList']
    // } else {
    //   actArr = []
    // }
    // this.cachedViews = [
    //   ...applyArr,
    //   ...actArr
    // ]
    // console.log(this.cachedViews, '>>>this.cachedViews')
  }
  isKeep: any = false
  toast: any = {}
  hideFooterList: any = [
    'CampaignsDetail',
    'UserInfo',
    'CampaignsApply',
    'ApplyDetail',
    'unSubscribe',
    'informForm'
  ]
  hideHeadList: any = [
    'unSubscribe',
    'informForm'
  ]
  cachedViews: any = []
  get key() {
    return this.$route.path
  }
  mounted() {
    this.$root.$on('showToast', (toast: any) => {
      this.toast = toast
    })
  }
  hasLogin() {
    (this as any).$refs.heard.loginFn()
  }
}
